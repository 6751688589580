import { Component } from '@angular/core';
import { LoginService, Action ,Agent} from './../../login.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-sidebar-left-inner',
  templateUrl: './sidebar-left-inner.component.html'
})
export class SidebarLeftInnerComponent {
  user: Agent;
  constructor(private  loginService: LoginService, public router: Router){
    
    this.user = loginService.getUser();
    if (this.user === undefined  || this.user === null)
    {
      //alert("not logged in")
      this.router.navigate(['/login']);
    }
  }
  ngOnInit(): void {
    
    
  }
}
