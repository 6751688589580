import { Component } from '@angular/core';
import { LoginService, Action , Agent, Company, Usercomp} from './../../login.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-header-inner',
  templateUrl: './header-inner.component.html'
})
export class HeaderInnerComponent {
  user: Agent;
  companys: Company[];
  usercomps: Usercomp[];
  constructor(private  loginService: LoginService, public router: Router){
    
    this.user = loginService.getUser();
    let that=this;
    if (this.user === undefined  || this.user === null)
    {
      this.router.navigate(['/login']);
    }
  }
  logout(){
    this.loginService.logout();;
  }
  isactive(company)
  {
    return this.loginService.idcompany==company.id;
  }
  onChangeCompany(event)
  {
    let Id = +event.target.dataset.idd||0;
    this.loginService.setCompany(this.loginService.companys,Id);
    location.reload();
  }
}
