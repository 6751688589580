import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService, Product, Action } from './../login.service';

@Component({
  selector: 'app-productsbox',
  templateUrl: './productsbox.component.html',
  styleUrls: ['./productsbox.component.css']
})
export class ProductsboxComponent implements OnInit {
  products:Product[]; 
  constructor(private  loginService: LoginService, public router: Router) { }

  ngOnInit() {
  }

  ngAfterViewInit(): void {

    let that=this;
    this.loginService.getProducts({do(error:number,result:any){
      that.products = result.data.product_list.products ;
    }});
  }

}
