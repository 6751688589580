import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService, Agent, Action } from './../login.service';
@Component({
  selector: 'app-agentsbox',
  templateUrl: './agentsbox.component.html',
  styleUrls: ['./agentsbox.component.css']
})
export class AgentsboxComponent implements OnInit {
  agents:Agent[]; 
  constructor(private  loginService: LoginService, public router: Router) { }
  
  ngOnInit() {
  }
  ngAfterViewInit(): void {

    let that=this;
    this.loginService.getAgents({do(error:number,result:any){
      that.agents = result.data.agent_list.agents ;
    }})
  } 
}
