export const adminLteConf = {
  skin: 'green',
   //isSidebarLeftCollapsed: true,
   
   //isSidebarLeftMouseOver: true,
//  isSidebarLeftMini: true,
  // sidebarRightSkin: 'dark',
  // isSidebarRightCollapsed: true,
  // isSidebarRightOverContent: true,
   layout: 'normal',
   //isSidebarLeftExpandOnOver: true,
  sidebarLeftMenu: [
    {label: 'Menu Principal', separator: true,code: "ROT"},
    {label: 'Acceuil', route: '/', iconClasses: 'fa fa-road', pullRights: [{text: 'bandido', classes: 'label pull-right bg-green'}],code: "ََAFF001"},
    {label: 'Affaires', route: '/excel', iconClasses: 'fa fa-road',code: "INT001"},
    {label: 'Interactions', route: '/alert', iconClasses: 'fa fa-user',code: "MAN"},
    {label:  'Clients',  iconClasses: 'fa fa-car',code: "CLT", children: [
      {label: 'Listes des clients', route: '/client',code: "CLT001"},
      {label: 'Devis', route: '/devisn',code: "CLT002"},
      {label: 'Facture proforma', route: '/proformaclient',code: "CLT003"},
      {label: 'Bon de Livraison', route: '/bonclient',code: "CLT004"},
      {label: 'Facture', route: '/facturen',code: "CLT005"},
  	  {label: 'Paiements', route: '/paymentclient',code: "CLT006"},	  
    ]},
    {label:  'Produits',  iconClasses: 'fa fa-shopping-cart',code: "PRD", children: [
      {label: 'Mes produits', route: '/alert',code: "PRD001"},
      {label: 'Catégorie des produits', route: '/category',code: "PRD002"},
      {label: 'Pack', route: '/pack',code: "PRD003"},
    ]},
    {label:  'Fournisseur',  iconClasses: 'fa fa-ship',code: "FRN", children: [
      {label: 'Listes des fournisseurs', route: '/source',code: "FRN001"},
      {label: 'Bon de commande', route: '/fincom',code: "FRN002"},
      {label: 'Bon de réception', route: '/bonin',code: "FRN003"},
      {label: 'Paiements', route: '/payment',code: "FRN004"},
    ]},
    /*{label:  'Distributeurs',  iconClasses: 'fa fa-car', children: [
      {label: 'Listes des distributeurs', route: '/wholesale'},
      {label: 'Devis', route: '/devisdist'},
      {label: 'Bon de Livraison', route: '/bondist'},
      {label: 'Facture', route: '/facturedist'},
  	  {label: 'Paiements', route: '/paymentdist'},
	  ]},*/

    {label:  'Stock',  iconClasses: 'fa fa-calendar',code: "STK", children: [
      {label: 'Listes des magasins', route: '/warehouse',code: "STK001"},
      {label: 'Etat de stock', route: '/etat',code: "STK002"},
      {label: 'Transfert inter-magazins', route: '/transfer',code: "STK003"},
    ]},

    {label:  'Trésorie',  iconClasses: 'fa fa-money',code: "TRS", children: [
      {label: 'Overview', route: '/tresorie',code: "TRS002"},
      {label: 'Gestion de trésorie', route: '/tresoriecou',code: "TRS002"},
      {label: 'Paramètres des comptes', route: '/account',code: "TRS003"},
      {label: 'Catégorie des charges', route: '/accountcategorie',code: "TRS004"},
      {label: 'Type de compte', route: '/accounttype',code: "TRS005"},
    ]},
    /*{label:  'Comptabilité',  iconClasses: 'fa fa-money', children: [
      {label: 'Journal', route: '/journal'},
      {label: 'Capitaux (entrée - Sortie)', route: '/capitaux'},
      {label: 'Charges', route: '/ccharge'},
      {label: 'trésorerie', route: '/ctresorie'},
      {label: 'TVA', route: '/tvatotal'},
      {label: 'Compte de résultat', route: '/compteresultat'},
      {label: 'Bilan', route: '/bilan'},
      {label: 'Paramètre', route: '/parametre'},
    ]},*/    
    {label: 'Mobile app', route: '/bonclientapp',code: "APK001"},
  ]
};
