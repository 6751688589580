import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CookieService } from 'angular2-cookie/services/cookies.service';
import { adminLteConf } from './admin-lte.conf';

import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';

import { LayoutModule } from 'angular-admin-lte';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';

import { LoadingPageModule, MaterialBarModule } from 'angular-loading-page';
import { GraphQLModule } from './graphql.module';
import { HttpClientModule } from '@angular/common/http';
import { ProductsboxComponent } from './productsbox/productsbox.component';
import { FormsModule } from '@angular/forms';
import { AgentsboxComponent } from './agentsbox/agentsbox.component';
import { BoxModule, BoxSmallModule as MkBoxSmallModule  } from 'angular-admin-lte';


import { FullCalendarModule } from '@fullcalendar/angular'; // the main connector. must go first
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin
//import interactionPlugin from '@fullcalendar/interaction'; // a plugin

FullCalendarModule.registerPlugins([ // register FullCalendar plugins
  dayGridPlugin//,
  //interactionPlugin
]);
@NgModule({
  imports: [
    FullCalendarModule ,
    BrowserModule, FormsModule,//ChartsModule,
    AppRoutingModule,
    BoxModule,
    MkBoxSmallModule,
    
    CoreModule,
    LayoutModule.forRoot(adminLteConf),
    LoadingPageModule, MaterialBarModule, GraphQLModule, HttpClientModule
  ],
  providers: [
    CookieService ],
  declarations: [
    AppComponent,
    HomeComponent,
    ProductsboxComponent,
    AgentsboxComponent,
    
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
