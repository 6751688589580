import { Router } from '@angular/router';
import { Component, AfterViewInit } from '@angular/core';
import { LayoutStore } from 'angular-admin-lte';
import * as Prism from 'prismjs';
import { LoginService, Journalhdview, Product, Productview, Company, Usercomp, Party } from '../login.service';
import * as moment from 'moment';
import { CalendarOptions } from '@fullcalendar/angular';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements AfterViewInit {

  calendarOptions: CalendarOptions;

  usercomps: Usercomp[];
  CAT: string;
  Marge: string;
  Margep1: string;
  Margep2: string;
  journalhds: Journalhdview[];
  products: Product[];
  productviews: Productview[];
  loadded = false;
  constructor(private loginService: LoginService, public router: Router) {

  }
  /**
   * @method ngAfterViewInit
   */
  ngAfterViewInit() {
    Prism.highlightAll();
    let that = this;
    this.loginService.addLoadAction({
      do() {
        that.loadded = true;
        that.loginService.getJournalhdviews({
          do(error: number, result: any) {
            that.journalhds = result.data.journalhdview_list.journalhdviews
              .filter(function (journalhdview: { jhd_kind: number; }) { return journalhdview.jhd_kind == 2 || journalhdview.jhd_kind == 5 });;
            let data = [];
            let data1 = [];
            that.CAT = that.currency(that.getCAT()).toString();

            for (let i = 0; i < 12; i++) {
              data.push(that.getCATMonth(i));
              data1.push(that.getMargeMonth(i));
            }
            that.chartData = [
              { data: data, label: 'CAT' },
              { data: data1, label: 'Marge' },
            ];



          }
        });
        that.loginService.getProducts({
          do(error: number, result: any) {
            that.products = result.data.product_list.products.
              filter(function (product: { typpe: number; }) { return product.typpe == 1 });;
          }
        });

        that.loginService.getProductviews({
          do(error: number, result: any) {
            that.productviews = result.data.productview_list.productviews.
              filter(function (productv: { stockqt: number; }) { return productv.stockqt > 0 }).
              filter(function (product: { typpe: number; }) { return product.typpe == 1 });;;

          }
        });


      }
    });

    that.loginService.getPartys(2, {
      do(error: number, result: any) {
        let clients = result.data.party_list.partys;
        let evts = [];
        clients.forEach((c: Party) => {
          let dt = c.daterelance;
          if (dt)
            evts.push(
              {
                id: c.id,
                title: c.party_name,
                date: moment(that.momentf(c.daterelance)).format("YYYY-MM-DD"),
                
              }
            );
        });

        that.calendarOptions = {
          initialView: 'dayGridMonth',
          selectable :  true,
          eventClick: that.handleDateClick.bind(that), // bind is important!
          locale: 'fr',
          events: evts

        };
      }
    });

  }
  handleDateClick(arg) {

      
      window.open(window.location.origin+'/#/client/'+arg.event.id, "_blank");
      return false;
 
  }
  momentf(x) {
    if (!x) return "";
    return moment.utc(x, 'DD-MM-YYYY HH:mm:SS');
  }
  onChangeCompany(event: { target: { dataset: { idd: string | number; }; }; }) {
    let Id = +event.target.dataset.idd || 0;
    this.loginService.setCompany(this.loginService.companys, Id);
    location.reload();
  }
  getCAT() {
    //23 mustapha
    return this.journalhds.reduce((a, b) => a + (b.total - b.vat), 0);
  }
  getCATsansMus() {

    return this.journalhds.reduce((a, b) => a + (b.jhd_party == 23 ? 0 : b.total - b.vat), 0);
  }
  getCATMus() {

    return this.getCAT() - this.getCATsansMus();
  }
  getCATMonth(month: Number) {
    //23 mustapha
    return this.journalhds.reduce((a, b) => a + ((b.jhd_party == 23 || this.getMonth(b) != month) ? 0 : b.total - b.vat), 0);
  }
  getjhdMonth(month: Number) {
    //23 mustapha
    return this.journalhds.filter(a => this.getMonth(a) == month);
  }
  getMarge() {
    //23 mustapha
    return this.journalhds.reduce((a, b) => a + (b.jhd_party == 23 ? 0 : (b.total - b.vat - b.buy)), 0);
  }
  getMargeMonth(month: Number) {
    //23 mustapha
    return this.journalhds.reduce((a, b) => a + ((b.jhd_party == 23 || this.getMonth(b) != month) ? 0 : (b.total - b.vat - b.buy)), 0);
  }
  getStock() {
    return this.productviews.reduce((a, b) => a + ((!b.stockqt || b.stockqt < 0) ? 0 : (b.stockqt * b.buyprice)), 0);
  }
  getMargep1() {
    return 100 * this.getMarge() / this.getCAT();
  }
  getMargep2() {
    return 100 * this.getMarge() / (this.getCAT() - this.getMarge());
  }
  float(a: string | number) {
    let v = (+a || 0);
    return (Math.round(v * 100) / 100).toFixed(2);

  }

  int(a: string | number) {
    let v = (+a || 0);
    return (Math.round(v)).toString() + " %";
  }

  currency(a: number) {
    let v = (+a || 0);


    return (Math.round(v * 100) / 100).toLocaleString("fr-fr", { style: "currency", currency: "Eur", minimumFractionDigits: 2 }).
      replace("€", this.loginService.getCurrency());
  }
  isactive(company: { id: number; }) {
    return this.loginService.idcompany == company.id;
  }
  todate(dat: string) {
    let day = +dat.substring(0, 2);
    let month = +dat.substring(3, 5);
    let year = +dat.substring(6, 10);
    var d = new Date(year, month - 1, day + 1);

    return d;
  }
  getMonth(hd: Journalhdview): Number {
    return moment(this.todate(hd.jhd_date)).month();
  }
  stringify(x: any) {
    return JSON.stringify(x);
  }
  chartData = [
  ];
  chartLabels = ['Janvier', 'Fevrier', 'Mars', 'Avril', 'Mai', 'Juin', 'Juiller', 'Aout', 'Septembre', 'Octobre', 'Novembre', 'Decembre'];
}
