import { LoginService } from './login.service';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CanActivate } from '@angular/router';
import { HomeComponent } from './home/home.component';
const routes: Routes = [
  {
  path: '',
  data: {
      title: 'Acceuil'
  },
  children: [
    {
      path: '',
      component: HomeComponent
    }, {
      path: 'accordion',
      canActivate: [LoginService] ,
      loadChildren: './+accordion/accordion.module#AccordionModule',
      data: {
        title: 'Collaborateurs'
      }
    }, {
      path: 'alert',
      canActivate: [LoginService] ,
      loadChildren: './+alert/alert.module#AlertModule',
      data: {
        title: 'Produits',
      }
    }, {
      path: 'promo',
      //canActivate: [LoginService] ,
      loadChildren: './+promo/promo.module#PromoModule',
      data: {
        title: 'Famille des produits',
      }
    }, {
      path: 'etat',
      canActivate: [LoginService] ,
      loadChildren: './+etat/etat.module#EtatModule',
      data: {
        title: 'Etat de stock',
      }
    }, {
      path: 'source',
      canActivate: [LoginService] ,
      loadChildren: './+source/client.module#ClientModule',
      data: {
        title: 'Fournisseurs',
      }
    }, {
      path: 'account',
      //canActivate: [LoginService] ,
      loadChildren: './+account/account.module#AccountModule',
      data: {
        title: 'Comptes',
      }
    }, {
      path: 'tresorie',
      //canActivate: [LoginService] ,
      loadChildren: './+tresorie/tresorie.module#TresorieModule',
      data: {
        title: 'Overiew',
      }
    }, {
      path: 'tresoriecou',
      //canActivate: [LoginService] ,
      loadChildren: './+tresoriecou/tresoriecou.module#TresoriecouModule',
      data: {
        title: 'Gestion de trésorie',
      }
    }, {
      path: 'factureclient',
      //canActivate: [LoginService] ,
      loadChildren: './+factureclient/factureclient.module#FactureclientModule',
      data: {
        title: 'Gestion des facture',
      }
    }, {
      path: 'tvatotal',
      //canActivate: [LoginService] ,
      loadChildren: './+tvatotal/tvatotal.module#TvatotalModule',
      data: {
        title: 'Gestion des TVA',
      }
    }, {
      path: 'payment',
      canActivate: [LoginService] ,
      loadChildren: './+payment/payment.module#PaymentModule',
      data: {
        title: 'Paiements',
      }
    }, {
      path: 'paymentclient',
      canActivate: [LoginService] ,
      loadChildren: './+paymentclient/paymentclient.module#PaymentclientModule',
      data: {
        title: 'Paiements',
      }
    }, {
      path: 'nbs',
      //canActivate: [LoginService] ,
      loadChildren: './+nbs/paymentclient.module#PaymentclientModule',
      data: {
        title: 'Paiements',
      }
    }, {
      path: 'paymentdist',
      canActivate: [LoginService] ,
      loadChildren: './+paymentdist/paymentdist.module#PaymentdistModule',
      data: {
        title: 'Paiements',
      }
    }, {
      path: 'warehouse',
      canActivate: [LoginService] ,
      loadChildren: './+warehouse/warehouse.module#WarehouseModule',
      data: {
        title: 'Magasins',
      }
    }, {
      path: 'client',
      canActivate: [LoginService] ,
      loadChildren: './+client/client.module#ClientModule',
      data: {
        title: 'Clients',
      }
    }, {
      path: 'client/:id',
      canActivate: [LoginService] ,
      loadChildren: './+client/client.module#ClientModule',
      data: {
        title: 'Clients',
      }
    }, {
      path: 'wholesale',
      canActivate: [LoginService] ,
      loadChildren: './+wholesale/wholesale.module#WholesaleModule',
      data: {
        title: 'Distributeurs',
      }
    },  {
      path: 'activite',

      loadChildren: './+activite/activite.module#ActiviteModule',
      data: {
        title: 'Charges',
      }
    },  {
      path: 'settings',

      loadChildren: './+settings/activite.module#ActiviteModule',
      data: {
        title: 'Paramètres',
      }
    },{
      path: 'centres',
      //canActivate: [LoginService] ,
      loadChildren: './+centres/activite.module#ActiviteModule',
      data: {
        title: 'Paramètres des catégories ',
      }

    },{
      path: 'step',
      //canActivate: [LoginService] ,
      loadChildren: './+step/step.module#StepModule',
      data: {
        title: 'Etapes',
      }
    },{
      path: 'accounttype',
      //canActivate: [LoginService] ,
      loadChildren: './+accounttype/accounttype.module#AccounttypeModule',
      data: {
        title: 'Type de comptes',
      }
    },{
      path: 'accountcategory',
      //canActivate: [LoginService] ,
      loadChildren: './+accountcategory/accountcategory.module#AccountcategoryModule',
      data: {
        title: 'Type de comptes',
      }
    },{
      path: 'category',
      //canActivate: [LoginService] ,
      loadChildren: './+category/category.module#CategoryModule',
      data: {
        title: 'Categories',
      }
    },{
      path: 'societe',
      //canActivate: [LoginService] ,
      loadChildren: './+societe/societe.module#SocieteModule',
      data: {
        title: 'Sociétés',
      }
    },{
      path: 'pendrive',
      //canActivate: [LoginService] ,
      loadChildren: './+pendrive/societe.module#SocieteModule',
      data: {
        title: 'Gestin des Affaires',
      }
    },{
      path: 'frame',
      loadChildren: './frame/frame.module#FrameModule',
      data: {
        title: 'frames',
      }
    }, {
      path: 'fin',
      canActivate: [LoginService] ,
      loadChildren: './+fin/fin.module#FinModule',
      data: {
        title: 'Fournisseur > Bon de réception',
      }
    }, {
      path: 'fincom',
      canActivate: [LoginService] ,
      loadChildren: './+fincom/fincom.module#FinComModule',
      data: {
        title: 'Fournisseur > Bon de commande',
      }
    }, {
      path: 'fin/:id',
      canActivate: [LoginService] ,
      loadChildren: './+fin/fin.module#FinModule',
      data: {
        title: 'Fournisseur > Bon de réception',
      }
    },{
      path: 'bonclient',
      canActivate: [LoginService] ,
      loadChildren: './+bonclient/bonclient.module#BonclientModule',
      data: {
        title: 'Client > Bon de livraison',
      }
    },{
      path: 'bonclient/:id',
      canActivate: [LoginService] ,
      loadChildren: './+bonclient/bonclient.module#BonclientModule',
      data: {
        title: 'Client > Bon de Livraison',
      }

    },{
      path: 'bonin',
      canActivate: [LoginService] ,
      loadChildren: './+bonin/bonclient.module#BonclientModule',
      data: {
        title: 'Client > Bon de Réception',
      }
    },{
      path: 'bonin/:id',
      canActivate: [LoginService] ,
      loadChildren: './+bonin/bonclient.module#BonclientModule',
      data: {
        title: 'Client > Bon de Réception',
      }

    },{
      path: 'facturen',
      //canActivate: [LoginService] ,
      loadChildren: './+facturen/bonclient.module#BonclientModule',
      data: {
        title: 'Client > Facture',
      }
    },{
      path: 'facturen/:id',
      //canActivate: [LoginService] ,
      loadChildren: './+facturen/bonclient.module#BonclientModule',
      data: {
        title: 'Client > Facture',
      }

    },{
      path: 'pack',

      loadChildren: './+pack/bonclient.module#BonclientModule',
      data: {
        title: 'Client > Pack',
      }
    },{
      path: 'pack/:id',

      loadChildren: './+pack/bonclient.module#BonclientModule',
      data: {
        title: 'Client > Pack',
      }

    },/*{
      path: 'entree',

      loadChildren: './+entree/bonclient.module#BonclientModule',
      data: {
        title: 'Fournisseur > Facture',
      }
    },{
      path: 'entree/:id',

      loadChildren: './+pack/bonclient.module#BonclientModule',
      data: {
        title: 'Fournisseur > Factur',
      }

    },*/{
      path: 'proformaclient',
      canActivate: [LoginService] ,
      loadChildren: './+proformaclient/bonclient.module#BonclientModule',
      data: {
        title: 'Client > Facture Proforma',
      }
    },{
      path: 'proformaclient/:id',
      canActivate: [LoginService] ,
      loadChildren: './+proformaclient/bonclient.module#BonclientModule',
      data: {
        title: 'Client > Facture Performa',
      }
    },{
      path: 'commandeclient',
      canActivate: [LoginService] ,
      loadChildren: './+commandeclient/bonclient.module#BonclientModule',
      data: {
        title: 'Client > Bon de commande',
      }
    },{
      path: 'commandeclient/:id',
      canActivate: [LoginService] ,
      loadChildren: './+commandeclient/bonclient.module#BonclientModule',
      data: {
        title: 'Client > Bon de commande',
      }
    },{
      path: 'devisn',
      //canActivate: [LoginService] ,
      loadChildren: './+devisn/bonclient.module#BonclientModule',
      data: {
        title: 'Client > Devis',
      }
    },{
      path: 'devisn/:id',
      //canActivate: [LoginService] ,
      loadChildren: './+devisn/bonclient.module#BonclientModule',
      data: {
        title: 'Client > Devis',
      }
    },{
      path: 'devis',
      //ocanActivate: [LoginService] ,
      loadChildren: './+devis/devis.module#DevisModule',
      data: {
        title: 'Client > Devis',
      }
    },{
      path: 'bonclientapp',
      canActivate: [LoginService] ,
      loadChildren: './+bonclientapp/bonclientapp.module#BonclientappModule',
      data: {
        title: '',
      }
    },{
      path: 'bondist',
      canActivate: [LoginService] ,
      loadChildren: './+bondist/bondist.module#BondistModule',
      data: {
        title: 'Distributeur > Bon de Livraison',
      }
    },{
      path: 'devisdist',
      loadChildren: './+devisdist/devisdist.module#DevisdistModule',
      data: {
        title: 'Distributeur > Devis',
      }
    },{
      path: 'facturedist',
      loadChildren: './+facturedist/facturedist.module#FacturedistModule',
      data: {
        title: 'Distributeur > Facture',
      }
    },{
      path: 'bondist/:id',
      canActivate: [LoginService] ,
      loadChildren: './+bondist/bondist.module#BondistModule',
      data: {
        title: 'Distribueteur > Bon de Livraison',
      }
    }
    ,{
      path: 'transfer',
      canActivate: [LoginService] ,
      loadChildren: './+transfer/transfer.module#TransferModule',
      data: {
        title: 'Stock > Transferts inter-magasins',
      }
    }, {
      path: 'layout',
      data: {
        title: 'Layout',
      },
      children: [
        {
          path: 'configuration',
          loadChildren: './+layout/configuration/configuration.module#ConfigurationModule',
          data: {
            title: 'Configuration'
          }
        }, {
          path: 'custom',
          loadChildren: './+layout/custom/custom.module#CustomModule',
          data: {
            title: 'Disable Layout'
            // disableLayout: true
          }
        }, {
          path: 'content',
          loadChildren: './+layout/content/content.module#ContentModule',
          data: {
            title: 'Content'
          }
        }, {
          path: 'header',
          loadChildren: './+layout/header/header.module#HeaderModule',
          data: {
            title: 'Header'
          }
        }, {
          path: 'sidebar-left',
          loadChildren: './+layout/sidebar-left/sidebar-left.module#SidebarLeftModule',
          data: {
            title: 'Sidebar Left'
          }
        }, {
          path: 'sidebar-right',
          loadChildren: './+layout/sidebar-right/sidebar-right.module#SidebarRightModule',
          data: {
            title: 'Sidebar Right'
          }
        },
      ]
    }, {
      path: 'boxs',
      data: {
        title: 'Boxs',
      },
      children: [
        {
          path: 'box',
          loadChildren: './+boxs/box-default/box-default.module#BoxDefaultModule',
          data: {
            title: 'Box'
          }
        }, {
          path: 'info-box',
          loadChildren: './+boxs/box-info/box-info.module#BoxInfoModule',
          data: {
            title: 'Info Box'
          }
        }, {
          path: 'small-box',
          loadChildren: './+boxs/box-small/box-small.module#BoxSmallModule',
          data: {
            title: 'Small Box'
          }
        }
      ]}, {
        path: 'dropdown',
        loadChildren: './+dropdown/dropdown.module#DropdownModule',
        data: {
          title: 'Erreur 403',
        }
      }, {
        path: 'excel',
        loadChildren: './+excel/tabs.module#TabsModule',
        data: {
          title: 'excel',
        }
      }, {
        path: 'tabs',
        canActivate: [LoginService] ,
        loadChildren: './+tabs/tabs.module#TabsModule',
        data: {
          title: 'Tabs',

        }
      }
    ]
  }, {
    path: 'form',

    data: {
      title: 'Form',
    },
    loadChildren: './+form/input-text/input-text.module#InputTextModule',
  }, {
    path: 'login',
    loadChildren: './+login/login.module#LoginModule',
    data: {
      customLayout: true
    }
  }, {
    path: 'register',
    canActivate: [LoginService] ,
    loadChildren: './+register/register.module#RegisterModule',
    data: {

    }
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true ,onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
